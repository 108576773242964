/* global variables */
:root {
    /* --scrollbar-width: thin; */
    --scrollbar-width-legacy: .5rem;
    --scrollbar-color-thumb: black;
    /* --scrollbar-color-track: transparent; */
}


/* hide the main scrollbar */
html {
    -ms-overflow-style: none;  /* hide scrollbar on Edge browser */
    scrollbar-width: none; /* hide scrollbar on Firefox browser */
    overflow-y: scroll;
}

html::-webkit-scrollbar {
    display: none; /* hide scrollbar on Chrome, Safari, and Opera browser */
}

html::-webkit-scrollbar-button {
    display: none;
}

body {
    font-family: 'Roboto Flex Variable', sans-serif;
}

.chinese {
    font-family: 'Noto Sans HK Variable', sans-serif;
}


small {
    font-size: 12px;
}


/* responsive embeds */
.responsive-object {
    position: relative;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* index pages center text overlays */
.image-container {
    position: relative;
    text-align: center;
}
  
.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 86%;
    background: rgba(255, 255, 255, 0.90);
    transform: translate(-50%, -50%);
    transition: 0.3s ease;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.image-container:hover .overlay {
    opacity: 1;
}

/* tutorial index pages image animation filter */
.tutorial-image-container {
    position: relative;
    text-align: center;
    filter: grayscale(1);
    transition: filter 0.3s ease;
}

.tutorial-image-container:hover {
    filter: grayscale(0%);
}

.tutorial-image-container:hover .tutorial-overlay {
    animation: showOverlay 0.25s forwards;
    animation-delay: 0.25s;
}

.tutorial-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 85%;
    height: 95%;
    background: rgba(255, 255, 255, 0.90);
    transform: translate(-50%, -50%);
    transition: opacity 0.1s ease;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@keyframes showOverlay {
    to {
        opacity: 1;
    }
}

/* dark links, overwriting bootstrap defaults */
a {
    color: #000;
    text-decoration: underline;
}

/* back to top button */
#backToTopBtn {
    display: none;
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 99; 
}

/* use dark navbar toggler, which fixes case when using white navbar and black offcanvas */
.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%2833, 37, 41, 0.75%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e")
}



/* Modern browsers with `scrollbar-*` support */
@supports (scrollbar-width: auto) {
    .scroller {
        scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
        scrollbar-width: var(--scrollbar-width);
        /* hide the scrollbar button */
        scrollbar-button { 
            display: none;
        };
    }
}

/* Legacy browsers with `::-webkit-scrollbar-*` support */
@supports selector(::-webkit-scrollbar) {
    .scroller::-webkit-scrollbar-thumb {
        background: var(--scrollbar-color-thumb);
    }
    .scroller::-webkit-scrollbar-track {
        background: var(--scrollbar-color-track);
    }
    .scroller::-webkit-scrollbar {
        max-width: var(--scrollbar-width-legacy);
        max-height: var(--scrollbar-width-legacy);
    }
    /* hide the scrollbar button */
    .scroller::-webkit-scrollbar-button {
        display: none; 
    }
}

/* make carousel buttons round */
.carousel-indicators {
    bottom: -3rem;
}

.carousel-indicators [data-bs-target] {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
}


nav div.fw-bolder.text-uppercase {
    font-size: 15px;
}

/* add scroll margin, so that it does not go all the way to the top */
nav a.indextext {
    scroll-margin-top: 10rem;
    font-size: 15px;
}


/* add scroll margin, so that it does not go all the way to the top */
nav a[name="subtext"] {
    scroll-margin-top: 10rem;
    font-size: 13px;
}

/* add scroll margin, so that it does not go all the way to the top */
nav div.indextextsmall {
    font-size: 13px;
}


/* .col-xl-2 {
    flex: 0 0 18.5%;
    max-width: 18.5%;
}


.col-xl-10 {
    flex: 0 0 81.5%;
    max-width: 81.5%;
} */

@media (min-width: 1400px) {
    .row-cols-xxl-8 > * {
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }
}

/* add progress bar */
.progress-container {
    width: 100%;
    background-color: transparent;
    height: var(--scrollbar-width-legacy);
    display: block;
}

.progress-bar {
    background-color: var(--scrollbar-color-thumb);
    width: 0%;
    display: block;
    height: inherit;
}


/* change form field colors to blackish colors */
.form-control:focus {
    border-color: black;
    box-shadow: 0 0 0 .25rem rgba(0, 0, 0, 0.25);
}

.form-select:focus {
    border-color: black;
    box-shadow: 0 0 0 .25rem rgba(0, 0, 0, 0.25);
}


select option:hover {
    box-shadow: 0 0 10px 100px black inset !important;
    color:white;
}
select option:checked {
    box-shadow: 0 0 10px 100px black inset !important;
}


#tutorial-credits {
    font-size: 12px;
}


.thumbnail-text {
    font-size: 0.4rem; /* Default size */
  }

  @media (min-width: 576px) {
    .thumbnail-text {
      font-size: 0.75rem; /* Small devices */
    }
  }

  @media (min-width: 768px) {
    .thumbnail-text {
      font-size: 0.95rem; /* Medium devices */
    }
  }

  @media (min-width: 992px) {
    .thumbnail-text {
      font-size: 1rem; /* Large devices */
    }
  }

  @media (min-width: 1200px) {
    .thumbnail-text {
      font-size: 1.1rem; /* Extra large devices */
    }
  }

/* height of abstract set to 30% of page height */
#abstract {
    height: 25vh; 
}