// bsl.scss
// Customize bootstrap for BSL


@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";


$input-focus-border-color: "gray-dark";
$input-focus-box-shadow: "gray";
$btn-close-focus-shadow: "gray"; // to fix later
$border-radius: 0;
$modal-content-border-radius: 0;

$font-stack: "Roboto Flex Variable", sans-serif;

@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/bootstrap";